import React, { Component, createElement } from 'react';
import { transform } from 'js/css-transform';
import HTML from 'html-parse-stringify';
import _, { find as _find, forEach as _forEach, findIndex as _findIndex } from 'lodash';
import he from 'he';
import growl from '@crystallize/react-growl';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { getNameInCurrentLanguage } from 'helpers/intl';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Editor from 'js/cc-editor/build/ckeditor';
import { updateZoom } from 'js/actions/LayoutActions';

class Editable extends Component {
  constructor(props) {
    super(props);
    this.checkOverflow = this.checkOverflow.bind(this);
    this.state = {
      overflowHere: false,
    };
  }

  componentDidMount = () => {
    const { attributes, id, params, setFontSizeSettings } = this.props;
    this.getEditorErrors();

    if (typeof attributes['data-fontsize-section'] && attributes['data-fontsize-section']) {
      let sectionFontSize = attributes['data-fontsize-section'];
      let source = '';
      if (typeof params && params.length > 0) {
        _.forEach(params, (param, key) => {
          if (attributes['data-name'] === param.name) {
            source = param.value;
            sectionFontSize = this.extractClassFromHTML(source) ?? sectionFontSize;
          }
        });
      }
      setFontSizeSettings(id, sectionFontSize);
    }
  };

  componentDidUpdate = prevProps => {
    const { attributes, id, params, setFontSizeSettings } = this.props;
    if (JSON.stringify(prevProps.params) !== JSON.stringify(this.props.params)) {
      if (typeof attributes['data-fontsize-section'] && attributes['data-fontsize-section']) {
        let sectionFontSize = attributes['data-fontsize-section'];
        if (typeof params && params.length > 0) {
          let source = '';
          _.forEach(params, param => {
            if (attributes['data-name'] === param.name) {
              source = param.value;
              sectionFontSize = this.extractClassFromHTML(source) ?? sectionFontSize;
            }
          });
          setFontSizeSettings(id, sectionFontSize);
        }
      }
    }
  };

  extractClassFromHTML = htmlString => {
    // Create a temporary HTML element and set its HTML content
    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlString;

    // Find all span elements
    const spanElements = tempElement.querySelectorAll('span');

    if (spanElements.length > 0) {
      // Get the last span element
      const lastSpan = spanElements[spanElements.length - 1];

      // Get the class list of the last span element
      const classList = lastSpan.className;

      // Split the class list into individual classes
      const classes = classList.split(' ');

      // Filter the classes that start with "text-"
      const textClasses = classes.filter(className => className.startsWith('text-'));

      // Return the found "text-" classes (if any)
      return textClasses?.[0];
    }
  };

  checkIt = editor => {
    if (this.props.getDeviceType() === 'mobile') {
      if ('virtualKeyboard' in navigator) {
        if (this.props.showKeyboard) {
          navigator.virtualKeyboard.overlaysContent = false;
          navigator.virtualKeyboard.show();
        } else {
          navigator.virtualKeyboard.hide();
        }
      }
      this.getZoomData(editor);
    }
  };

  getZoomData = e => {
    const { attributes } = this.props;
    let zoomFactor = '';
    if (attributes && attributes['zoom-data'] !== undefined) {
      zoomFactor = attributes['zoom-data'];
    } else {
      zoomFactor = 1;
    }
    if (e !== null) {
      const blockContainer = this.getBlockParent(e.sourceElement, 'DIV');
      const editElementHeight = blockContainer.clientHeight;
      const editElementWidth = blockContainer.clientWidth;
      const zoomData = [];
      zoomData[0] = editElementHeight;
      zoomData[1] = editElementWidth;
      zoomData[2] = blockContainer;
      zoomData[3] = zoomFactor;
      this.props.updateZoom(zoomData);
    }
  };

  update = editor => {
    const { id, attributes, updateParam, bgColor, resizeBlock, autoResizeSupported } = this.props;
    const { overflowHere } = this.state;
    let value = editor.getData();
    // getElementToChange(attributes['data-name']);
    const stopResize = attributes?.className.includes('copyright');
    let overflow = this.checkOverflowOnAutoResize(editor);
    const default_image = false;
    const errors = [{ overflow, default_image }];
    if (autoResizeSupported === true && !stopResize) {
      while (overflow === true && this.props.blockBiggerDisable !== true && this.props.bigger !== null) {
        if (resizeBlock(this.props.id, this.props.bigger) === true) {
          updateParam(id, attributes['data-name'], value, errors);
          overflow = this.checkOverflowOnAutoResize(editor);
        }
      }
    }

    if (overflow === true) {
      /*  this.checkOverflowOnAutoResize(editor) does not return an error message.
          Blocks will auto resize on overflow, -> no overflow error message will be
          thrown but if the block can't resize -> this.checkOverflow will execute to get an error message
      */

      overflow = this.checkOverflow(editor);
    }

    if (attributes.hasOwnProperty('data-fix')) {
      if (value.indexOf('<p>') !== -1) {
        value = this.replaceParagraphsWithHeading(attributes, value);
      }
    }

    if (attributes['data-background']) {
      const appendMetaTag = `${value}<meta content="${bgColor}" name="bgColor"/>`;
      updateParam(id, attributes['data-name'], appendMetaTag, errors);
    } else {
      updateParam(id, attributes['data-name'], value, errors);
    }
    if (overflow) {
      this.setState({ overflowHere: true });
    } else if (overflowHere) {
      this.setState({ overflowHere: false });
    }
  };

  replaceParagraphsWithHeading = (attributes, value) => {
    const fixValue = attributes['data-fix'];
    return value.replace(/<p>/g, '<' + fixValue + '>').replace(/<\/p>/g, '</' + fixValue + '>');
  };

  checkOverflow = e => {
    const {
      intl: { messages },
    } = this.props;
    if (e !== null) {
      const blockContainer = this.getBlockParent(e.sourceElement, 'DIV');
      if (blockContainer.scrollHeight > blockContainer.clientHeight) {
        if (!blockContainer.classList.contains('no_overflow') && blockContainer.classList.contains('editable')) {
          growl({
            message: <b>{messages.editor.found_textoverflow}</b>,
            type: 'error',
          });
          return true;
        }
      }
    }
    return false;
  };

  checkOverflowOnAutoResize = e => {
    if (e !== null) {
      const blockContainer = this.getBlockParent(e.sourceElement, 'DIV');
      if (blockContainer.scrollHeight > blockContainer.clientHeight) {
        if (!blockContainer.classList.contains('no_overflow') && blockContainer.classList.contains('editable')) {
          return true;
        }
      }
    }
    return false;
  };

  getBlockParent = (e, searchFor) => {
    let container = e.parentElement;
    const tagName = container.tagName.toString();
    if (tagName !== searchFor) {
      container = this.getBlockParent(container, searchFor);
    }
    return container;
  };

  loop = elements => {
    let result = [];

    _.forEach(elements, (value, key) => {
      if (value.type === 'text') {
        result.push(he.decode(value.content));
      } else if (value.type === 'tag' && !_.includes(this.forbiddenTags, value.name)) {
        let { name } = value;
        const attributes = { key };

        _.forEach(value.attrs, (attr, attrKey) => {
          switch (attrKey) {
            case 'class': {
              attributes.className = attr;
              break;
            }

            case 'style': {
              if (attr) {
                try {
                  attributes[attrKey] = transform(attr);
                } catch (err) {
                  console.log('error', err);
                }
              }
              break;
            }

            default: {
              attributes[attrKey] = attr;
              break;
            }
          }
        });
        /* if (name === 'a') {
          ({ name, attributes } = this.getLinkData({ name, attributes }));
        } else */
        if (name === 'html' || name === 'head' || name === 'body') {
          name = 'div';
        }

        if (name === 'img' || name === 'br' || name === 'hr') {
          result.push(createElement(name, { ...attributes }));
        } else if (attributes.isRoot) {
          result = this.loop(value.children);
        } else {
          result.push(createElement(name, { ...attributes }, this.loop(value.children)));
        }
      }
    });

    return result;
  };

  getContent = () => {
    const { attributes, children, params } = this.props;

    const param = _find(params, ['name', attributes['data-name']]);
    if (param) {
      return this.loop(HTML.parse('<div isRoot="true">' + param.value + '</div>'));
    }
    // parse the ast tree and return the default html value for the template (children)
    // children

    return this.loop(children);
  };

  getBlindText = lines => {
    // todo: richtige stelle? blindtext einfügen, auf overflow checken, und ggf. ändern
    const lorem = [
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      'Donec facilisis vitae risus non viverra.',
      'Vivamus condimentum sed ipsum vitae ullamcorper.',
      'Vestibulum ac mi malesuada, fermentum magna non, tincidunt ligula.',
      'Nam in elit sapien. Maecenas interdum quam tellus, a malesuada augue hendrerit eget.',
      'Praesent ullamcorper non felis id pulvinar.',
      'Donec efficitur semper ultrices.',
      'Integer aliquam cursus urna ac ullamcorper.',
      'Curabitur pulvinar quis lectus at vehicula.',
      'Quisque tempus mattis diam non sodales.',
      'Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.',
      'Curabitur turpis velit, semper eget consectetur nec, commodo in felis.',
      'Sed tristique elit ut quam vestibulum egestas.',
      'Aenean aliquam urna id auctor euismod.',
      'Sed fringilla accumsan nibh. Nunc tincidunt, nisi sit amet pharetra bibendum, purus erat hendrerit ipsum',
      'Vestibulum lectus neque, hendrerit at mi sit amet, tristique rhoncus leo.',
      'In ultricies felis vel maximus scelerisque.',
      'Proin a pharetra lorem.',
      'Donec in neque eu purus elementum mattis.',
      'Suspendisse nisl erat, gravida eu aliquet vitae, ornare venenatis risus.',
      'Sed rutrum eleifend massa sit amet elementum.',
      'In ut ante tellus.',
      'Vestibulum imperdiet quam eu nisl condimentum eleifend.',
      'Duis mi arcu, scelerisque quis ipsum a, gravida facilisis lorem.',
      'Donec quis ultricies urna.',
      'Suspendisse molestie feugiat nisi sit amet malesuada.',
      'Curabitur porta sed nibh non tincidunt.',
      'Cras nec lacus dui.',
      'Nam fermentum tincidunt diam, sed posuere nisl dictum a.',
      'Curabitur ipsum turpis, porta vitae neque mattis, congue eleifend eros.',
      'Mauris id rhoncus nulla.',
      'Integer facilisis, nisi vel maximus hendrerit, massa urna gravida quam, quis semper lorem eros at augue.',
      'Integer magna sem, blandit scelerisque cursus id, eleifend non magna. Etiam eu tempor nunc.',
      'Phasellus in suscipit urna, eu malesuada nunc.',
      'Morbi consequat, nisi at tristique sodales, sapien tortor euismod tortor, eget commodo mauris ligula et est.',
      'Fusce sollicitudin accumsan posuere.',
      'Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae',
      'Ut luctus magna lacus, eget aliquam mi pretium nec.',
      'Duis tempus tincidunt tellus quis faucibus.',
      'Morbi mattis placerat est. Ut eget mi mauris.',
    ];
    let blindText = '';
    for (let i = 0; i < lines; i++) {
      blindText = blindText + ' ' + lorem[i];
      if (i + 1 === lorem.length) {
        lines -= i;
        i = 0;
      }
    }
    return blindText;
  };

  getEditorErrors = () => {
    const { attributes, params } = this.props;
    const param = _find(params, ['name', attributes['data-name']]);
    if (param) {
      if (param.errors) {
        this.setState({ overflowHere: param.errors.overflow });
      }
    }
  };

  // return the HTML content as string for the CK editor
  getEditorContent = () => {
    const { attributes, children, params, publisherInfo, editionRV } = this.props;
    const {
      intl: { messages },
    } = this.props;
    const param = _find(params, ['name', attributes['data-name']]);
    if (param) {
      // this.getEditorErrors(param);
      return param.value;
    }
    if (typeof attributes['data-fill'] !== 'undefined') {
      const fillLines = attributes['data-fill'];
      return this.getBlindText(fillLines);
    }

    const parentData = {
      company: editionRV?.creator?.parent?.profile?.company || '',
      street: editionRV?.creator?.parent?.profile?.street || '',
      houseNumber: editionRV?.creator?.parent?.profile?.house_number || '',
      zipCode: editionRV?.creator?.parent?.profile?.zip_code || '',
      city: editionRV?.creator?.parent?.profile?.city || '',
      logo: editionRV?.creator?.parent?.client_main_logo?.variations[0]?.file?.web_link || '',
      apartmentNumber: editionRV?.creator?.parent?.profile?.apartment_number || '',
      addressLine2: editionRV?.creator?.parent?.profile?.address_line2 || '',
      phone: editionRV?.creator?.parent?.profile?.phone || '',
      email: editionRV?.creator?.parent?.profile?.email || '',
      mobile: editionRV?.creator?.parent?.profile?.mobile || '',
      fax: editionRV?.creator?.parent?.profile?.fax || '',
      website: editionRV?.creator?.parent?.profile?.website || '',
      title: editionRV?.creator?.parent?.profile?.title || '',
      salutation: editionRV?.creator?.parent?.profile?.salutation || '',
      firstname: editionRV?.creator?.parent?.profile?.first_name || '',
      lastname: editionRV?.creator?.parent?.profile?.last_name || '',
      country: editionRV?.creator?.parent?.profile?.country_name || '',
    };

    parentData.salutation =
      parentData.salutation === 'mrs' ? messages.salutationOptions.mrs : messages.salutationOptions.mr;

    const childrenData = HTML.stringify(children);
    const childrenDataStep1 = childrenData.replace(/{herausgeber}/gi, publisherInfo);
    const childrenDataStep2 = childrenDataStep1.replace(/{firma}/gi, parentData?.company);
    const childrenDataStep3 = childrenDataStep2.replace(/{strasse}/gi, parentData?.street);
    const childrenDataStep4 = childrenDataStep3.replace(/{hausnummer}/gi, parentData?.houseNumber);
    const childrenDataStep5 = childrenDataStep4.replace(/{plz}/gi, parentData?.zipCode);
    const childrenDataStep6 = childrenDataStep5.replace(/{stadt}/gi, parentData?.city);
    const childrenDataStep7 = childrenDataStep6.replace(/{logo}/gi, parentData?.logo);
    const childrenDataStep8 = childrenDataStep7.replace(/{wohnungsnummer}/gi, parentData?.apartmentNumber);
    const childrenDataStep8a = childrenDataStep8.replace(/{adresszeile2}/gi, parentData?.addressLine2);
    const childrenDataStep9 = childrenDataStep8a.replace(/{telefon}/gi, parentData?.phone);
    const childrenDataStep10 = childrenDataStep9.replace(/{email}/gi, parentData?.email);
    const childrenDataStep11 = childrenDataStep10.replace(/{mobil}/gi, parentData?.mobile);
    const childrenDataStep12 = childrenDataStep11.replace(/{fax}/gi, parentData?.fax);
    const website = parentData?.website.replace(/^https?:\/\//, '');
    const childrenDataStep13 = childrenDataStep12.replace(/{website}/gi, website);
    const childrenDataStep14 = childrenDataStep13.replace(/{titel}/gi, parentData?.title);
    const childrenDataStep15 = childrenDataStep14.replace(/{anrede}/gi, parentData?.salutation);
    const childrenDataStep16 = childrenDataStep15.replace(/{vorname}/gi, parentData?.firstname);
    const childrenDataStep17 = childrenDataStep16.replace(/{nachname}/gi, parentData?.lastname);
    const childrenDataFinal = childrenDataStep17.replace(/{land}/gi, parentData?.country);

    return childrenDataFinal;
  };

  SpecialCharactersEmoji = editor => {
    editor.plugins
      .get('SpecialCharacters')
      .addItems('Emoji', [
        { title: 'smiley face', character: '😊' },
        { title: 'rocket', character: '🚀' },
        { title: 'wind blowing face', character: '🌬️' },
        { title: 'floppy disk', character: '💾' },
        { title: 'heart', character: '❤️' },
      ]);
  };

  render() {
    const { name: Tag, attributes, settings, appIntl, errors, id, disabled} = this.props;
    const { overflowHere } = this.state;
    const licenseKey = globalThis.CKEditorLicenseKey ?? undefined; // defined in parameters.php

    let minOwnFontSize = parseInt(settings?.font_size_min, 10); // in pt
    let maxOwnFontSize = parseInt(settings?.font_size_max, 10); // in pt

    if (typeof attributes['min-own-font-size'] !== 'undefined') {
      minOwnFontSize = parseInt(attributes['min-own-font-size'], 10);
    }

    if (typeof attributes['max-own-font-size'] !== 'undefined') {
      maxOwnFontSize = parseInt(attributes['max-own-font-size'], 10);
    }

    let ownOptions = ['tiny', 'small', 'default', 'big', 'huge'];

    /* pt to px
     * Up to now the specifications for min and max font size were made in px. That should now be changed.
     * Therefore new following calculation is required. As usual, the output for the customer is in pt.
     * The ckeditor needs the values in pixels.
     * */

    if (settings.own_font_size && minOwnFontSize < maxOwnFontSize) {
      ownOptions = ['default'];

      for (let i = minOwnFontSize; i <= maxOwnFontSize; i++) {
        const title = i + 'pt';
        const pt = i;
        const px = (pt * settings.dpi) / (25.4 * 2.835);
        const model = Math.round(px) + 'px';

        const findDoubleEntry = _findIndex(ownOptions, ['title', title]);

        if (findDoubleEntry === -1) {
          const ownOptionsContent = { title, model };
          ownOptions.push(ownOptionsContent);
        }
      }
    }

    let toolbarConfig = [
      'heading',
      '|',
      'bold',
      'italic',
      'underline',
      'link',
      'subscript',
      'superscript',
      '|',
      'fontFamily',
      'fontSizeDropdown',
      'fontColor',
      'fontBackgroundColor',
      'alignment',
      'indent',
      'outdent',
      '|',
      'horizontalLine',
      'bulletedList',
      'numberedList',
      'insertTable',
    ];

    let headingConfig = [
      { model: 'paragraph', view: 'p', title: 'Paragraph', class: 'ck-heading_paragraph' },
      { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
      { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
      { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
      { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
      { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
      { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
    ];
    const removePlugins = [];
    if (typeof attributes['data-custom-headings'] !== 'undefined') {
      const headingCustom = attributes['data-custom-headings'].replace(/\s/g, '').split(',');
      const headingConfigCustom = [];
      _forEach(headingCustom, entry => {
        const headingIndex = _findIndex(headingConfig, ['view', entry]);
        if (headingIndex >= 0) {
          headingConfigCustom.push(headingConfig[headingIndex]);
        }
      });
      headingConfig = headingConfigCustom;
    }

    // CKEditor throws without a license key but with premium plugins enabled.
    if (!licenseKey) {
      removePlugins.push('AIAssistant');
    }

    let iconsConfig = [];
    if (typeof attributes['data-icons'] !== 'undefined') {
      toolbarConfig.push('icon');
      iconsConfig = attributes['data-icons'].replace(/\s/g, '').split(',');
    }
    if (typeof attributes['data-custom-toolbar'] !== 'undefined') {
      toolbarConfig = attributes['data-custom-toolbar']
        .replace(/\s/g, '')
        .replace('fontSize', 'fontSizeDropdown')
        .replace('fontColor', 'fontColorDropdown') /* for this we need ckeditor changes from CD-951 */
        .split(',');

      const table = _find(toolbarConfig, 'insertTable');
      if (typeof table === 'undefined') {
        removePlugins.push('Table', 'TableToolbar');
      }

      const heading = _find(toolbarConfig, 'heading');
      if (typeof heading === 'undefined') {
        removePlugins.push('Heading');
      }
    } else if (Array.isArray(settings.text_toolbar_default) && settings.text_toolbar_default.length > 0) {
      toolbarConfig = settings.text_toolbar_default;
    }

    toolbarConfig.push('|');
    toolbarConfig.push('undo');
    toolbarConfig.push('redo');

    // When ChatGPT is enabled by data attribute, ensure toolbar contains the ai* items
    if (attributes['data-assistant'] !== undefined) {
      if (!toolbarConfig.includes('aiCommands')) {
        toolbarConfig.push('aiCommands');
      }
      if (!toolbarConfig.includes('aiAssistant')) {
        toolbarConfig.push('aiAssistant');
      }
    }

    const fontColors = [];
    const fontBackgroundColors = [];
    const fonts = [];

    if (settings.target) {
      _forEach(settings.target.font_colors, fontColor => {
        // limit font color
        if (attributes && attributes['data-custom-fontcolors']) {
          if (attributes['data-custom-fontcolors'].includes(fontColor?.label[0]?.name)) {
            fontColors.push({
              label: getNameInCurrentLanguage(fontColor.label, appIntl),
              color: fontColor.color,
            });
          }
        } else {
          fontColors.push({
            label: getNameInCurrentLanguage(fontColor.label, appIntl),
            color: fontColor.color,
          });
        }
        /*
        limit font backgroundcolor - A notice. The font background color is selected from the created
        font colors, not from the background colors. This was already determined before the background colors
        and background elements were implemented. A change would be made quickly, but would have an impact on
        existing templates and editions. Don't do that.
         */

        if (attributes && attributes['data-custom-fontbackgroundcolors']) {
          if (attributes['data-custom-fontbackgroundcolors'].includes(fontColor?.label[0]?.name)) {
            fontBackgroundColors.push({
              label: getNameInCurrentLanguage(fontColor.label, appIntl),
              color: fontColor.color,
            });
          }
        } else {
          fontBackgroundColors.push({
            label: getNameInCurrentLanguage(fontColor.label, appIntl),
            color: fontColor.color,
          });
        }
      });

      _forEach(settings.target.fonts, font => {
        if (font.for_user) {
          fonts.push(font.css_path);
        }
      });
    }

    _forEach(settings.font_colors, fontColor => {
      // limit font colors
      if (attributes && attributes['data-custom-fontcolors']) {
        if (attributes['data-custom-fontcolors'].includes(fontColor?.label[0]?.name)) {
          fontColors.push({
            label: getNameInCurrentLanguage(fontColor.label, appIntl),
            color: fontColor.color,
          });
        }
      } else {
        fontColors.push({
          label: getNameInCurrentLanguage(fontColor.label, appIntl),
          color: fontColor.color,
        });
      }
      // limit font backgroundcolors
      if (attributes && attributes['data-custom-fontbackgroundcolors']) {
        if (attributes['data-custom-fontbackgroundcolors'].includes(fontColor?.label[0]?.name)) {
          fontBackgroundColors.push({
            label: getNameInCurrentLanguage(fontColor.label, appIntl),
            color: fontColor.color,
          });
        }
      } else {
        fontBackgroundColors.push({
          label: getNameInCurrentLanguage(fontColor.label, appIntl),
          color: fontColor.color,
        });
      }
    });

    _forEach(settings.fonts, font => {
      if (font.for_user) {
        fonts.push(font.css_path);
      }
    });

    const placeholders =
      this.props.editionRV !== null &&
      typeof this.props.editionRV.placeholder !== 'undefined' &&
      this.props.editionRV.placeholder !== null
        ? this.props.editionRV.placeholder[0]
        : ['not defined'];

    const customTableBackgroundColors = [];

    if (settings.target) {
      _forEach(settings.target.back_colors, backColor => {
        customTableBackgroundColors.push({
          label: getNameInCurrentLanguage(backColor.label, appIntl),
          color: backColor.color,
        });
      });
    }
    _forEach(settings.back_colors, backColor => {
      customTableBackgroundColors.push({
        label: getNameInCurrentLanguage(backColor.label, appIntl),
        color: backColor.color,
      });
    });

    const editorConfig = {
      removePlugins: ['Image', 'MediaEmbed', ...removePlugins],

      ai: {
        openAI: {
          /** @see EditionBundle/Resources/config/routing.yml where it is set */
          apiUrl: '/api/ai_assistant.json',
        }
      },

      // plugins: [],
      fontFamily: {
        options: fonts,
        supportAllValues: true,
      },
      fontSize: {
        options: ownOptions,
      },
      fontColor: {
        colors: fontColors,
      },
      fontBackgroundColor: {
        colors: fontBackgroundColors,
      },
      indentBlock: {
        offset: 1,
        unit: 'em',
      },
      heading: {
        options: headingConfig,
      },
      toolbar: toolbarConfig,
      iconConfig: {
        icons: iconsConfig,
      },
      placeholderConfig: {
        types: placeholders,
      },
      placeholderBrackets: {
        open: '{',
        close: '}',
      },

      table: {
        contentToolbar: [
          'tableColumn',
          'tableRow',
          'mergeTableCells',
          'tableCellProperties', // 'tableProperties' in case if we need it later
        ],

        // Configuration of the TableProperties plugin. In case if we need it later
        /* tableProperties: {
          backgroundColors: customTableBackgroundColors
        }, */

        // Configuration of the TableCellProperties plugin.
        tableCellProperties: {
          backgroundColors: customTableBackgroundColors,
        },
      },
      language: {
        ui: appIntl?.locale,
        content: appIntl?.locale,
      },
    };

    if (licenseKey) {
      editorConfig.licenseKey = licenseKey;
    }

    let errorText = '';
    const {
      intl: { messages },
    } = this.props;
    if (overflowHere || errors.overflow) {
      // attributes.className += ' overflow';
      errorText = messages.editor.text_overflow;
    }
    if (errors.default_image) {
      attributes.className += ' default-image';
      errorText += messages.editor.initial_image_available;
    }
    // let editorRef = uuidLib.v4();
    const ckedit = (
      <CKEditor
        editor={Editor}
        key={id + '_editor'}
        data={this.getEditorContent()}
        config={editorConfig}
        // readOnly={true}
        disabled={disabled}
        onLoad={editor => {
          this.update(editor);
          // const overflow = this.checkOverflow(editor);
          // this.setState({ overflowHere: overflow });

          // this.update(editor);
          // You can store the "editor" and use when it is needed.
          // console.log( 'Editor is ready to use!', editor );
        }}
        onBlur={(event, editor) => {
          this.update(editor);
        }}
        onFocus={(event, editor) => {
          this.checkIt(editor);
        }}
      />
    );

    let output = (
      <>
        <Tag {...attributes} contentEditable={false}>
          {ckedit}
        </Tag>
      </>
    );

    if (overflowHere) {
      // todo check for errors in param
      output = (
        <div className="overflow" data-tip={errorText} data-for="errorinfo">
          {output}
        </div>
      );
    }

    return <>{output}</>;
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    zHeight: state.layout.zHeight,
    zWidth: state.layout.zWidth,
    viewActive: state.layout.viewActive,
    editionRV: state.editor.editionRV,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateZoom: data => {
      dispatch(updateZoom(data));
    },
  };
};

export default withRouter(
  injectIntl(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(Editable)
  )
);
